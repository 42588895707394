<template>
    <SmartLinkItemComponent custom-tag="div" class="col-lg-6" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <div class="card-item">
            <SmartLinkStandaloneElement custom-tag="div" class="image" :codename="type.elements.image.codename">
                <NuxtImg provider="kontentAiProvider" v-if="firstOrDefault(model?.elements?.image?.value)?.url"
                    :src="firstOrDefault(model.elements.image.value)?.url" />
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement custom-tag="h3" class="title" :codename="type.elements.title.codename">
                {{ model.elements.title.value }}
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement custom-tag="div" class="description"
                :codename="type.elements.description.codename">
                <RichText :value="model.elements.description.value" />
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement custom-tag="div" class="cta" :codename="type.elements.call_to_action.codename">
                <CallToAction custom-class="btn btn-outline-primary btn-sm"
                    v-if="model?.elements?.callToAction?.value?.length > 0"
                    v-for="item in pageStore.getListAs<CallToActionModel>(model.elements.callToAction.value)"
                    :model="item" />
            </SmartLinkStandaloneElement>
        </div>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { type CallToActionModel, type CardItemModel, contentTypes } from '~/models';
const type = contentTypes.card_item;
const pageStore = usePageStore();
const props = defineProps<{ model: CardItemModel }>();
</script>